/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-13 17:50:44
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import {
genModels } from
'@/plugins/widget/c-form/rules';
import {
auditStatus,
payCatalog,
getLabel } from
'@/utils/options';

export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    width: 100 },

  {
    label: '姓名',
    kname: 'name' },

  {
    label: '电话',
    kname: 'mobile' },

  {
    label: '邮箱',
    kname: 'email' },

  {
    label: '备注',
    kname: 'remark' },

  {
    label: '操作',
    kname: 'btns',
    width: 160 }];};




export var searchOption = [
{
  kname: 'name',
  label: '搜索姓名：',
  component: {
    name: 'el-input' } },


{
  kname: 'mobile',
  label: '搜索电话：',
  component: {
    name: 'el-input' } }];



export var formData = function formData() {return {
    list: [{}, {}] };};